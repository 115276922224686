<template>
  <div>
    <!-- Header -->
    <div class="header bg-base py-7">
      <div class="container">
        <div class="header-body text-center mb-1">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Create New Account</h1>
              <p class="text-lead text-white">
                Create unforgettable experiences with us!
              </p>
              <p class="bg-white">IT'S FREE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center mb-5 mt-5">
        <div class="col-lg-6 col-md-8">
          <base-register></base-register>
          <div class="row mt-3">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <a href="/login" class="text-light">
                <small> <i class="fa fa-arrow-left" /> Back to login </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseRegister from "@/components/BaseRegister.vue";
export default {
  components: {
    BaseRegister,
  },
};
</script>
<style></style>
