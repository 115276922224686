<template>
  <div class="py-0 px-0 my-0 mx-0 mt-4">
    <!-- WARNING: Dev's do not remove it plz -->
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
    <!-- ./WARNING: Dev's do not remove it plz -->

    <div :class="{ 'mt-5': !onlyBookButton }">
      <div
        v-if="(!item.name || !skeletonLoader) && !onlyBookButton"
        class="container-fluid mt-5"
        style="max-width: 83.33333333%"
      >
        <el-skeleton :rows="6" animated>
          <template slot="template">
            <div style="padding: 14px">
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; height: 100px"
              /><br />
              <el-skeleton-item
                class="mt-3"
                variant="image"
                style="width: 100%; height: 480px"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 50%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 40%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 100%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 100%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 40%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
            </div>
          </template>
        </el-skeleton>
      </div>

      <base-event-landing-page
        v-if="(item.theme === 'base' || onlyBookButton) && skeletonLoader"
        :item="item"
        :modals="modals"
        @addTicket="ticketModal($event)"
        :onlyBookButton="onlyBookButton"
      />

      <tabloid-event-landing-page
        v-if="(item.theme === 'tabloid' || onlyBookButton) && skeletonLoader"
        :item="item"
        :modals="modals"
        @addTicket="ticketModal($event)"
      />

      <eventalk-landing-page
        v-if="(item.theme === 'eventalk' || onlyBookButton) && skeletonLoader"
        :item="item"
        :modals="modals"
        @addTicket="ticketModal($event)"
      />
    </div>
  </div>
</template>

<script>
import BaseEventLandingPage from "@/components/Events/BaseEventLandingPage.vue";
import TabloidEventLandingPage from "@/components/Events/TabloidEventLandingPage.vue";
import EventalkLandingPage from "@/components/Events/EventalkLandingPage.vue";

import {
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
import swal from "sweetalert2";
import VueGtag from "vue-gtag";
const moment = require("moment-timezone");

export default {
  name: "PublicEventPage",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    BaseEventLandingPage,
    TabloidEventLandingPage,
    EventalkLandingPage,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
    logo: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_LOGO,
      description: "Sidebar app logo",
    },
  },
  data() {
    return {
      attendee: { name: "", email: "", type: "attendees" },
      item: { id: null },
      skeletonLoader: false,
      organizer: {},
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      copyrightYear: new Date().getFullYear(),
      snipcartToken: null,
      snipcartScript: null,
      onlyBookButton: false,
    };
  },
  async created() {
    // Data fetched and ready to manipulate on this component
    this.onlyBookButton = this.$route?.query?.btnOnly === "true";
    // Get Snipcart Token key dynamically according to user
    this.snipcartScript = document.getElementById("snipcart");

    await this.fetchItem()
      .then(() => {
        this.snipcartToken =
          this.organizer.snipcart_token ?? process.env.VUE_APP_SNIPCART_KEY;
      })
      .then(() => {
        // Set attributes to the HTML elements
        this.snipcartScript.setAttribute("data-api-key", this.snipcartToken);
      });
  },
  async updated() {
    // Set Snipcart script
    this.snipcartScript.setAttribute(
      "src",
      "https://cdn.snipcart.com/scripts/2.0/snipcart.js"
    );
  },
  beforeDestroy() {
    // Reset google analytics
    this.setGoogleAnalytics();
  },
  methods: {
    async fetchItem() {
      try {
        const id = (this.item.id = this.$route.params.id);
        let params = {
          include: "user,category,tags,tickets,venues",
        };

        await this.$store
          .dispatch("items/getPublic", { id, params })
          .then(() => (this.item = this.$store.getters["items/item"]))
          .then(() => (this.organizer = this.item.user))
          .then(() =>
            setTimeout(() => {
              this.skeletonLoader = true;
            }, "1000")
          );

        //to set at userGoogleAPIKey the users key
        // this.userGoogleAPIKey = 'G-P7904YVPK5'
        // this.setGoogleAnalytics(this.userGoogleAPIKey)

        try {
          // Send action to Google Analytics
          this.$gtag.event("bmis_view_public_event_page", {
            eventId: this.$route.params.id,
          });
        } catch (error) {
          // Notification
          this.$notify({
            type: "danger",
            message: error,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async ticketModal(attendee) {
      attendee.type = "attendees";
      attendee.personalized_ticket =
        attendee.guests.length > 1 && attendee.guests[0].quantity === 1;

      try {
        await this.$store
          .dispatch("attendees/addPublic", attendee)
          .then(
            () => (this.attendee = this.$store.getters["attendees/attendee"])
          );

        const ticketStatus = this.attendee.guests[0].is_paid
          ? "Paid"
          : "Payment pending";
        // const ticketPrice = this.attendee.guests[0].final_price ? this.attendee.guests[0].final_price : this.attendee.guests[0].price;
        const ticketNumbers = this.attendee.guests.map(
          ({ ticket_number }) => ticket_number
        );
        const ticketNumer =
          ticketNumbers.length > 1
            ? ticketNumbers.join(", ")
            : ticketNumbers[0];
        const totalTicketPrice = this.attendee.guests
          .map(
            (ticket) => ticket.quantity * (ticket.final_price || ticket.price)
          )
          .reduce((total, price) => total + price, 0);
        const confirmation = await swal.fire({
          title: `Success`,
          // text: "A few words about this sweet alert ...",
          html:
            "Your ticket has been booked, please save your Booking Number(s)<br> " +
            "<br><b>Booking Number(s): </b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            ticketNumer +
            "</button> <br></hr>" +
            "Total Ticket Price: " +
            totalTicketPrice +
            "<br>" +
            "Booking Status: " +
            ticketStatus +
            "<br>" +
            "You will receive soon a verification email with all the booking details",
          allowOutsideClick: false,
          allowEscapeKey: false,
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-outline-danger btn-fill",
          },
          icon: "success",
        });

        try {
          // Ticket booking is successful
          if (confirmation.value === true) {
            this.modals.openBooking = false;
          }
          if (this.onlyBookButton) {
            this.modals.openBooking = "reset";
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong, please try again!",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.error);
      }
    },
    goBack() {
      this.$router.push({ name: "List Events" });
    },
  },
};
</script>
