<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-base py-7 mb--100">
      <div class="container">
        <div class="text-center mt--5 mb-5">
          <div class="row justify-content-center">
            <div class="text-center" style="margin-bottom: 0px">
              <h1 class="text-white">Welcome Back</h1>
              <p class="text-lead text-white">
                Make every moment remarkable, organise and manage the best
                events in no-time!
              </p>
              <ul class="list-inline">
                <li
                  class="list-inline-item bg-white"
                  v-for="(value, index) in tagline"
                  :key="index"
                >
                  &nbsp;#{{ value }}&nbsp;
                </li>
              </ul>
            </div>

            <div class="col-12 text-center mt-2">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--8 pb-4">
      <div class="row justify-content-center mb--2">
        <div class="col-md-6">
          <card id="cta_new_event" type="frame">
            <div class="row justify-content-center">
              <div class="col-md-7 text-center mb-1 mt-1">
                <p class="surtitle text-muted">
                  Plan with purpose
                </p>
                <h6 class="surtitle text-muted mt-1">
                  Execute faster than light
                </h6>
              </div>
              <div class="col-md-5 text-center mt-1">
                <base-button
                    type="danger"
                    class="bg-gradient-danger"
                    icon
                    size="md"
                    @click="addGuestItem()"
                    data-testid="guest-create-item"
                >
                  <i class="fas fa-calendar-day" />
                  Create Event
                </base-button>
              </div>
            </div>
          </card>

        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card bg-gradient-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h3>Sign in with credentials</h3>
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                  class="mb-3"
                  name="Email"
                  type="email"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="email"
                  required
                />
                <validation-error :errors="apiValidationErrors.email" />

                <base-input
                  class="mb-1"
                  name="Password"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  required
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <div class="text-center mr-3 mt-5">
                  <base-button
                      block
                    type="primary"
                    native-type="submit"
                    class="my-2"
                    :disabled="loading"
                  >
                    Sign in
                  </base-button>
                </div>
                <div class="text-center">
                  <slot name="loading">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                  </slot>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <a href="/register" class="text-light"
                ><small> <i class="fa fa-user" /> Create FREE account</small>
              </a>
            </div>
            <div class="col-6 text-right">
              <a href="/password/reset" class="text-light"
                ><small>
                  <i class="fa fa-asterisk" /> Forgot your password?
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import GuestAddEventItem from "@/views/Pages/Guest/PublicAddEventItem.vue";

export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    email: "",
    password: "",
    tagline: ["event", "attendees", "tickets", "venues", "tags" ],
    loading: false,
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      setTimeout(() => {
        const user = {
          data: {
            type: "token",
            attributes: {
              email: this.email,
              password: this.password,
            },
          },
        };

        const requestOptions = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
          },
        };

        try {
          this.$store.dispatch("login", { user, requestOptions })
              .then((res) => {
                if (res?.error) {
                  this.$notify({
                    type: "danger",
                    message: res.error_description,
                  });
                } else if (res) {
                  this.$notify({
                    type: "danger",
                    message: "Oops, something went wrong!",
                  });
                }
                this.loading = false;
              });
        } catch (error) {
          console.log(error);
          this.loading = false;

          this.$notify({
            type: "danger",
            message: "Invalid credentials!",
          });
          this.setApiValidation(error.response.data.errors);
        }
      }, 500);
    },
    login() {
      console.log("Send");
    },
    addGuestItem() {
      this.$router.push({ name: "Public Add Event Item" });
    },
  },
};
</script>
