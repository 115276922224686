<template>
  <div class="ml--3 mr--3 mt-5">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6 mt-4">
        <div class="mb-3 mt-5">
          <a href="/login" class="text-default"
            ><small> <i class="fa fa-arrow-left" /> Back to login </small>
          </a>
        </div>
        <card id="cta_new_event" type="frame">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center mb-1 mt-1">
              <p class="surtitle text-muted">
                Plan with purpose, Execute faster than light
              </p>
              <h6 class="surtitle text-muted mt-1">
                We're thrilled you've chosen to embark on your event journey with us
              </h6>
            </div>
          </div>
        </card>

        <form ref="fast_event_form" @submit.prevent="handleSubmit">
          <div class="order-md-1">
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-md-8">
                  <h3 class="mb-0">Enter your full name and email</h3>
                </div>
              </div>

              <div class="card-body">
                <!-- Full Name -->
                <base-input
                  label="Full Name"
                  name="Name"
                  type="text"
                  placeholder="Enter your full name"
                  successMessage="Choose a unique and descriptive 'Name' for your event. This will help you stand out there."
                  v-model="user.name"
                  required
                />
                <validation-error :errors="apiValidationErrors.name" />

                <base-input
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  v-model="user.email"
                  required
                />
                <validation-error :errors="apiValidationErrors.email" />

                <div v-if="user.checkbox" class="text-center mt-5">
                  <base-checkbox v-model="user.agree" class="text-wrap mt-1">
                    <span id="fast-event-creation-and-registration" class="text-muted text-wrap">
                       By registering for this event, I consent to the
                      <a href="https://www.bookmeinscotty.com/terms-of-use/" target="_blank">Terms of Use</a>
                       and
                      <a href="https://www.bookmeinscotty.com/terms-and-conditions/" target="_blank">Terms & Conditions</a>
                      therefore I hereby agree to proceed
                    </span>
                  </base-checkbox>
                </div>

                <div
                  v-if="user.checkbox && !user.agree"
                  class="invalid-feedback text-center text-wrap mt-3"
                  style="display: block"
                >
                  Please agree with Terms & Conditions in order to proceed
                </div>

                <vue-recaptcha v-if="!showElementForPlaywright"
                  sitekey="6LfDZ5AlAAAAAAQcfEJ4FrQfkuUO2Lu2V8ziQoyr"
                  ref="recaptcha"
                  @verify="showTC()"
                  v-show="!user.checkbox"
                  class="mt-5"
                >
                  <base-button
                    size="lg"
                    outline
                    type="primary"
                    class="calendar-action-button"
                    block="true"
                    ><i class="fa fa-robot" />
                    I'm a Human
                  </base-button>
                </vue-recaptcha>

                <base-button
                    v-else
                    size="lg"
                    outline
                    type="primary"
                    class="calendar-action-button"
                    block="false"
                    data-testid="guest-recaptcha-btn"
                ></base-button>
              </div>
            </card>
          </div>

          <div class="order-md-2">
            <transition name="el-zoom-in-center">
              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-md-8">
                    <h3 class="mb-0">Begin the event journey</h3>
                  </div>
                </div>

                <div class="card-body">
                  <!-- Name -->
                  <base-input
                    label="Name *"
                    name="Name"
                    type="text"
                    placeholder="Name your event"
                    successMessage="Choose a unique and descriptive 'Name' for your event. This will help you stand out there."
                    v-model="item.name"
                    required
                  />
                  <validation-error :errors="apiValidationErrors.name" />

                  <base-input
                    type="text"
                    label="Summary *"
                    success-message="Include an easy-to-read, short description for your event, so users can quickly understand if this is the right event for them. This caption is on prominent position of your event from social posts to the landing page etc. (You can always add a long description after you create this event)"
                  >
                    <textarea
                      v-model="item.description"
                      placeholder="Few words what are you about to do..."
                      class="form-control"
                      rows="3"
                      name="Description"
                      required
                    >
                    </textarea>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.description" />

                  <div class="row">
                    <div class="col-md-6">
                      <base-input label="Timezone" required>
                        <el-select
                          v-model="item.timezone"
                          filterable
                          prepend-icon="fas fa-user"
                          placeholder="Select..."
                        >
                          <el-option
                            v-for="item in allTimezones"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>

                    <div class="col-md-6">
                      <base-input label="Begin at *">
                        <flat-picker
                          slot-scope="{ blur }"
                          @on-open="customFocus()"
                          @on-close="blur"
                          :config="{
                            allowInput: true,
                            enableTime: true,
                            minDate: 'today',
                          }"
                          class="form-control datepicker"
                          v-model="item.start_at"
                        >
                        </flat-picker>
                      </base-input>
                      <validation-error
                        :errors="apiValidationErrors.start_at"
                      />
                    </div>
                  </div>
                  <base-input label="Category *">
                    <el-select
                      name="category"
                      v-model="item.category.id"
                      prepend-icon="fas fa-user"
                      placeholder="Select..."
                      :filterable="true"
                    >
                      <el-option
                        v-for="single_category in allCategories"
                        :key="'A' + single_category.id"
                        :value="single_category.id"
                        :label="single_category.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </card>
            </transition>
          </div>

          <div class="order-md-3">
            <preview-event-item
              v-show="userDataValid()"
              :event-item="items"
              v-on:saveEvent="handleSubmit"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import PreviewEventItem from "@/views/Events/Management/PreviewEventItem.vue";
import { Select, Option } from "element-ui";
// packages
import { VueRecaptcha } from "vue-recaptcha";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import flatPicker from "vue-flatpickr-component";
import moment from "moment-timezone";

export default {
  name: "GuestAddEventItem",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    flatPicker,
    VueRecaptcha,
    ValidationError,
    PreviewEventItem,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [
        {
          id: "1",
          name: "Other & Miscellaneous",
          description: "Travel ideas for everyone",
        },
        {
          id: "2",
          name: "Food & Drinks",
          description: "Test333"
        },
        {
          id: "3",
          name: "Home & Decoration",
          description: "The latest trends in home decorations",
        },
        {
          id: "4",
          name: "Fashion & Design",
          description: "Stay in touch with the latest trends",
        },
        {
          id: "5",
          name: "Tech & Innovation",
          description: "A technological innovation or improved products",
        },
        {
          id: "6",
          name: "Health & Sports",
          description: "An apple a day keeps the doctor away",
        },
        {
          id: "7",
          name: "Travel & Adventure",
          description: "Travel ideas for everyone",
        },
        { id: "8", name: "Sports & Fitness", description: "Team games" },
        {
          id: "9",
          name: "Social & Gatherings",
          description:
            "Social gatherings or weekly happenings for private, home or hostel's events",
        },
        {
          id: "10",
          name: "Marketing & Technology",
          description:
            "Conferences or business events for digital marketing and latest technology trends",
        },
      ],
      allTags: [],
      allTimezones: [],
      tags: [],
      role: null,
      user: {
        name: null,
        email: null,
        agree: true,
        checkbox: false,
      },
      item: {
        type: "items",
        name: null,
        description: null,
        status: "private",
        public_search: false,
        start_at: new Date(),
        end_at: null,
        category: {
          type: "categories",
          id: null,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        relationshipNames: ["category", "tags"],
        user: null,
      },
      showAddModal: false,
    };
  },
  created() {
    // this.getCategories();
    this.item.category.id = this.allCategories[0].id;
    this.getTags();
    this.allTimezones = moment.tz.names();
    this.$emit("pageStyle", this.style);
  },
  computed: {
    items() {
      return {
        item: this.item,
        tags: this.tags,
        dates: [],
        metadata: {
          allTags: this.allTags,
          allCategories: this.allCategories,
        },
        loading: false,
      };
    },
    showElementForPlaywright() {
      return this.$route?.query?.e2e == 'true';
    }
  },
  methods: {
    async handleSubmit(values) {
      if (!this.user.agree) return;

      this.loading = true;

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      const user = {
        data: {
          type: "users",
          attributes: {
            name: this.user.name,
            email: this.user.email,
            role: "member",
            password: "********",
            password_confirmation: "********",
          },
        },
      };

      try {
        // 1st - Register user
        await this.$store
          .dispatch("register", {
            user,
            requestOptions,
            preventRegisterRedirection: true,
          })
          .then(() => {
            // 2nd - Create event
            this.createEvent(values);
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your event creation!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async createEvent(values) {
      this.item.event_type = values.event_type;
      if (this.item.event_type === "physical") {
        delete this.item.link;
      }
      if (
        this.item.event_type === "physical" ||
        this.item.event_type === "hybrid"
      ) {
        this.item.venue = {
          address_name: values.location.address,
          long: values.location.center[0],
          lat: values.location.center[1],
        };
      } else if (this.item.event_type === "tba") {
        this.item.total_capacity = -1;
      } else if (
        this.item.event_type === "virtual" &&
        values.unlimitedAttendees
      ) {
        this.item.total_capacity = -1;
      }

      this.item.description = this.item.description;
      this.item.start_at = moment(this.item.start_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.item.end_at = moment(this.item.start_at)
        .add(values.duration, values.timeunit)
        .format("YYYY-MM-DD HH:mm:ss");

      this.items.loading = true;

      try {
        delete this.item.id;

        await this.$store.dispatch("items/add", this.item);
        this.item = this.$store.getters["items/item"];

        this.items.loading = false;
        // Redirect to event
        this.redirect(this.item);

        this.$notify({
          type: "success",
          message: "Item added successfully.",
        });
      } catch (error) {
        this.items.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);

        if (this.item?.id) {
          this.$store.dispatch("items/destroy", this.item.id);
        }
        this.item.start_at = new Date();
      }
    },
    async getCategories() {
      try {
        await this.$store.dispatch("categories/list");
        this.allCategories = await this.$store.getters["categories/dropdown"];

        if (this.allCategories && this.allCategories.length) {
          this.item.category.id = this.allCategories[0].id;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    getTags() {
      this.allTags = [
        {
          id: "1",
          name: "BookMeInScotty",
          color: "#5e72e4",
        },
        {
          id: "2",
          name: "Event",
          color: "#5e72e4",
        },
        {
          id: "3",
          name: "SeizeTheMoment",
          color: "#5e72e4",
        },
      ];
      this.tags = [
        {
          id: "1",
        },
        {
          id: "2",
        },
        {
          id: "3",
        },
      ];

      this.item.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag.id,
          type: "tags",
        });
      });
    },
    goBack() {
      this.$router.push({ name: "Login" });
    },
    redirect(item) {
      this.$router.push({
        name: "Edit Event",
        params: { id: item.id },
      });
    },
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar")[0].focus();
      }, 10);
    },
    userDataValid() {
      const emailRegex =
        /^(?=.{1,320}$)(?!.*\.{2,})(?!.*[.@]{2,})(?!^[.@]|[@_-]$)([a-zA-Z0-9._-]{1,64})@(?=.{1,255}$)([a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-_]+)*\.[a-zA-Z0-9-_]{2,})$/;
      return this.user.name && emailRegex.test(this.user.email);
    },
    showTC() {
      this.user.checkbox = true;
    },
  },
};
</script>
